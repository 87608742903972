<template>
    <section class="bg-white br-b-8 pb-4">
        <div class="row mx-0">
            <div class="col">
                <p class="text-general f-600 f-17 text-center">
                    Motivos de cambio de precio
                </p>
                <echart :options="motivos_aprobados" />
                <el-popover
                v-model="visibleTenderoCancelacion"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center mx-0 position-relative">
                        <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleTenderoCancelacion = !visibleTenderoCancelacion" />
                        Motivos
                    </div>
                    <div v-for="(activo, r) in aprobados" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${activo.color}`" />
                        <div class="col">
                            {{ activo.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.value) }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.percent,1) }}%
                        </div>
                    </div>
                    <div v-if="activos.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleTenderoCancelacion = !visibleTenderoCancelacion">
                        Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
            <div class="col">
                <p class="text-general f-600 f-17 text-center">
                    Motivos de rechazo cambio de precio
                </p>
                <echart :options="motivos_rechazados" />
                <el-popover
                v-model="visibleTenderoCancelacion2"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center mx-0 position-relative">
                        <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleTenderoCancelacion2 = !visibleTenderoCancelacion2" />
                        Motivos
                    </div>
                    <div v-for="(activo, r) in rechazados" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${activo.color}`" />
                        <div class="col">
                            {{ activo.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.value) }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.percent,1) }}%
                        </div>
                    </div>
                    <div v-if="activos.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleTenderoCancelacion2 = !visibleTenderoCancelacion2">
                        Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
            <div class="col">
                <p class="text-general f-600 f-17 text-center">
                    Finalizacion de solicitud
                </p>
                <echart :options="motivos_finalizados" />
                <el-popover
                v-model="visibleTenderoCancelacion3"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center mx-0 position-relative">
                        <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleTenderoCancelacion3 = !visibleTenderoCancelacion3" />
                        Finalizacion de solicitud
                    </div>
                    <div v-for="(activo, r) in finalizados" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${activo.color}`" />
                        <div class="col">
                            {{ activo.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.value) }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.percent,1) }}%
                        </div>
                    </div>
                    <div v-if="activos.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleTenderoCancelacion3 = !visibleTenderoCancelacion3">
                        Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];

export default {
    props: {
        aprobados : {
            type: Array,
            default: () => []
        },
        aprobadosGrafica : {
            type: Array,
            default: () => []
        },
        rechazados : {
            type: Array,
            default: () => []
        },
        rechazadosGrafica : {
            type: Array,
            default: () => []
        },
        finalizados : {
            type: Array,
            default: () => []
        },
        finalizadosGrafica : {
            type: Array,
            default: () => []
        },
    },
    data(){
        let self = this
        return {
            motivos_aprobados: {
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${self.agregarSeparadoresNumero(params.percent)}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            motivos_rechazados: {
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${self.agregarSeparadoresNumero(params.percent)}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            motivos_finalizados: {
                color: ['#28D07B', '#DBDBDB',],
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${self.agregarSeparadoresNumero(params.percent)}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            activos: [
                {
                    color: 'red'
                },
                {
                    color: 'green'
                },
                {
                    color: 'orange'
                },
                {
                    color: 'green'
                }
            ],
            visibleTenderoCancelacion: false,
            visibleTenderoCancelacion2: false,
            visibleTenderoCancelacion3: false,
        }
    },
    watch: {
        aprobadosGrafica(val){
            this.motivos_aprobados.dataset.source = val
        },
        rechazadosGrafica(val){
            this.motivos_rechazados.dataset.source = val
        },
        finalizadosGrafica(val){
            this.motivos_finalizados.dataset.source = val
        },
        // porcentajes(val){
        //     this.optionRechazado.title.subtext = `${this.agregarSeparadoresNumero(val.rechazos)}%`
        //     this.optionTenderoCancelacion.title.subtext = `${this.agregarSeparadoresNumero(val.cancelacion_tienda)}%`
        //     this.optionClienteCancelacion.title.subtext = `${this.agregarSeparadoresNumero(val.cancelacion_cliente)}%`
        // },

    },
}
</script>

<style lang="scss" scoped>

</style>
